import { useEffect, useState } from 'react'
import axios from 'axios'
import { StatisticsWidget5 } from '../../../_metronic/partials/widgets'
import { toast } from "react-toastify";
import { formatPrice } from '../../../_metronic/helpers/utils';
import { useAuth } from '../auth';


const Index = () => {
    const { haveAccess, currentUser } = useAuth()
    const [data, setData] = useState()

    const getWalletsStats = () => {
        axios
            .get(`${process.env.REACT_APP_API_V2_URL}/reports/wallet`)
            .then((response) => {
                setData(response.data)
            })
            .catch((err) => {
                if (err && err.response && err.response?.data && err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error("Technical Error, Please Try Again");
                }
            });
    }

    useEffect(() => {
        getWalletsStats()
    }, [])


    return (
        <>
            {haveAccess(currentUser, 'reports_v2') ?
                <div className='row g-5 g-xl-8'>
                    <div className='col-xl-3'>
                        <StatisticsWidget5
                            className='card-xl-stretch mb-xl-8'
                            svgIcon='wallet'
                            color='white'
                            iconColor='primary'
                            title={data?.total_cash ? formatPrice(data?.total_cash) : '$0'}
                            description='Total Cash Deposited in Wallet'
                            optional='(Till Now)'
                        />
                    </div>

                    <div className='col-xl-3'>
                        <StatisticsWidget5
                            className='card-xl-stretch mb-xl-8'
                            svgIcon='wallet'
                            color='dark'
                            iconColor='white'
                            title={data?.cash_owed ? formatPrice(data?.cash_owed) : '$0'}
                            titleColor='white'
                            description='Total Cash Available in Wallet'
                            optional='(Today)'
                            descriptionColor='white'
                        />
                    </div>

                    <div className='col-xl-3'>
                        <StatisticsWidget5
                            className='card-xl-stretch mb-xl-8'
                            svgIcon='dollar'
                            color='danger'
                            iconColor='white'
                            title={data?.cash_consumed ? formatPrice(data?.cash_consumed) : '$0'}
                            titleColor='white'
                            description='Cash Consumed'
                            descriptionColor='white'
                        />
                    </div>

                    <div className='col-xl-3'>
                        <StatisticsWidget5
                            className='card-xl-stretch mb-5 mb-xl-8'
                            svgIcon='profile-user'
                            color='info'
                            iconColor='white'
                            title={data?.cash_users ? data?.cash_users : 0}
                            titleColor='white'
                            description='Active Wallet Users'
                            descriptionColor='white'
                        />
                    </div>
                </div>
                :
                <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
                    You do not have permission to view
                </div>
            }
        </>
    )
}

export default Index