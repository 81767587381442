import { KTIcon } from "../../../../_metronic/helpers";
import { formatPrice } from "../../../../_metronic/helpers/utils";

const PointsModalHeader = ({
  user,
  setOpenPointsModal,
  setOpenTopUpWalletModal,
  haveAccess,
  currentUser
}) => {
  return (
    <div className="modal-header flex-column">
      <div className="d-flex w-100 justify-content-between align-items-center">
      <div>
      <h2 className="fw-bolder mb-2">{`Cash Wallet of ${user?.first_name} ${user?.last_name}`}</h2>
      <h3>Total Wallet Balance: <span className="fw-bolder text-success">{user?.points ? formatPrice(user?.points) : '$0'}</span></h3>
      </div>
      <div className="d-flex flex-column align-items-end">
      <button
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-kt-users-modal-action="close"
        onClick={() => {
          setOpenPointsModal(false);
        }}
        style={{ cursor: "pointer" }}
      >
        <KTIcon iconName="cross" className="fs-1" />
      </button>
   {haveAccess(currentUser, 'wallet_balance') && <div className="d-flex gap-2">
      <button className="btn btn-sm btn-success"
      onClick={() => setOpenTopUpWalletModal({
        status:true,
        type:'add'
      })}
      >
        <KTIcon iconName="dollar" className="fs-1" /> Add Cash to Wallet
      </button>
      <button className="btn btn-sm btn-danger"
      onClick={() => setOpenTopUpWalletModal({
        status:true,
        type:'remove'
      })}
      >
        <KTIcon iconName="minus" className="fs-1" /> Remove Cash from Wallet
      </button>
      </div>
      }
      </div>
      </div>
    </div>
  );
};

export { PointsModalHeader };
