/* eslint-disable react/jsx-no-target-blank */
import {useEffect, useMemo} from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'
import axios from 'axios'
import { useLocation } from 'react-router-dom'


const SidebarMenuMain = () => {
  const intl = useIntl()
  const { currentUser, haveAccess, pendingApprovalsCount, setPendingApprovalsCount } = useAuth()
  const { pathname } = useLocation()
  const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY
  const isProd = stripeKey.indexOf('pk_live') === 0

  const getPendingApprovalsCount = () => {
    axios
    .get(`${process.env.REACT_APP_API_URL}/listing/approvals/count`)
    .then((res) => {
      setPendingApprovalsCount(res.data);
    })
    .catch((err) => {
      console.error(err);
    });
  }

  useEffect(() => {
    getPendingApprovalsCount();
  },[pathname]);


  const pendingListingFieldsCount = useMemo(() => {
    return pendingApprovalsCount && pendingApprovalsCount?.pending_fields?.reduce((acc, current) => acc + Number(current?.count), 0);
  }, [pendingApprovalsCount]);

  return (
    <>
      {
        (haveAccess(currentUser, 'user_ban')) ?
          <SidebarMenuItemWithSub
            to='/users'
            icon='user'
            title='Users'
            fontIcon='bi-layers'
          >
            <SidebarMenuItem
              to='/user-ban'
              icon='people'
              title='Banned Users'
              fontIcon='bi-layers'
            />
          </SidebarMenuItemWithSub>
          :
          (haveAccess(currentUser, 'user') && currentUser?.access_level !== 3) && <SidebarMenuItem
            to='/users'
            icon='user'
            title='Users'
            fontIcon='bi-layers'
          />
      }
     {haveAccess(currentUser, 'org_list') &&
     <SidebarMenuItem
        to='/organizations'
        icon='abstract-26'
        title='Organizations'
        fontIcon='bi-layers'
      />
      }
      {
        ((haveAccess(currentUser, "listing_tracker_logs_list") || haveAccess(currentUser, "listing_video_approvals")) && currentUser?.access_level !== 3) ?
          <SidebarMenuItemWithSub
            to='/listings'
            icon='address-book'
            title='Listings'
            fontIcon='bi-layers'
            count={pendingListingFieldsCount + pendingApprovalsCount?.pending_listings}
          >
            {haveAccess(currentUser, "listing_tracker_logs_list") && <SidebarMenuItem
              to='/listings-changes'
              icon='address-book'
              title='Listing Changes'
              fontIcon='bi-layers'
              
            />
            }
            {haveAccess(currentUser, "listing_video_approvals") && <SidebarMenuItem
              to='/listings-pending-approvals'
              icon='watch'
              title='Pending Approvals'
              fontIcon='bi-layers'
              count={pendingListingFieldsCount + pendingApprovalsCount?.pending_listings}
            />
            }
          </SidebarMenuItemWithSub>
          :
          <SidebarMenuItem
            to='/listings'
            icon='address-book'
            title='Listings'
            fontIcon='bi-layers'
          />

      }
      {(haveAccess(currentUser, 'bookings')) && <SidebarMenuItem
        to='/bookings'
        icon='plus-circle'
        title='Bookings'
        fontIcon='bi-layers'
      />
      }
      {(haveAccess(currentUser, 'bookings') && currentUser?.access_level !== 3) && <SidebarMenuItem
        to='/refunds'
        icon='dollar'
        title='Refunds'
        fontIcon='bi-layers'
        count={pendingApprovalsCount?.pending_refunds}
      />
      }
      {/* <SidebarMenuItem
        to='/metrics'
        icon='graph-up'
        title='Metrics'
        fontIcon='bi bi-activity'
      /> */}
      {haveAccess(currentUser, 'admin_roles') && currentUser?.access_level !== 3 &&
        <SidebarMenuItemWithSub
          to='/admins'
          icon='user-edit'
          title='Admins'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem
            to='/roles'
            icon='security-user'
            title='Roles'
            fontIcon='bi-layers'
          />
        </SidebarMenuItemWithSub>
      }
      {(haveAccess(currentUser, 'promo') && currentUser?.access_level !== 3) && <SidebarMenuItem
        to='/promocodes'
        icon='discount'
        title='Promo Codes'
        fontIcon='bi-layers'
      />
      }
      {(haveAccess(currentUser, 'reviews') && currentUser?.access_level !== 3) && <SidebarMenuItem
        to='/reviews'
        icon='subtitle'
        title='Reviews'
        fontIcon='bi-layers'
      />
      }
      {(haveAccess(currentUser, 'bulk') && currentUser?.access_level !== 3) && <SidebarMenuItem
        to='/messages'
        icon='messages'
        title='Messages'
      // fontIcon='bi-layers'
      />
      }
      {(haveAccess(currentUser, 'blacklist') && currentUser?.access_level !== 3) && <SidebarMenuItem
        to='/blacklist'
        icon='disconnect'
        title='Blacklist'
        fontIcon='bi-layers'
      />
      }
      {(haveAccess(currentUser, 'arrears') && currentUser?.access_level !== 3) && <SidebarMenuItem
        to='/arrearages'
        icon='dollar'
        title='Arrearages'
        fontIcon='bi-layers'
      />
      }
      {(haveAccess(currentUser, 'reports_v2') && currentUser?.access_level !== 3) && <SidebarMenuItem
        to='/wallets-stats'
        icon='wallet'
        title='Cash Wallets'
        fontIcon='bi-layers'
      >
      </SidebarMenuItem>
      }
      {(haveAccess(currentUser, 'reports') && currentUser?.access_level !== 3) && <SidebarMenuItemWithSub
        to='/reports'
        icon='chart-line'
        title='Reports'
        fontIcon='bi-layers'
      >
       <SidebarMenuItem
        to='/report-scheduler'
        icon='chart-line-up-2'
        title='Report Scheduler'
        fontIcon='bi-layers'
      />
      </SidebarMenuItemWithSub>
      }
      <SidebarMenuItem
        to='/account'
        icon='gear'
        title='My Account'
        fontIcon='bi-layers'
      >
      </SidebarMenuItem>
      <SidebarMenuItem
            to='/custom'
            icon='element-5'
            title='Custom Data'
            fontIcon='bi-layers'
      ></SidebarMenuItem>
      {/* <SidebarMenuItem
        to='/account'
        icon='gear'
        title='My Account'
        fontIcon='bi-layers'
      /> */}
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
    
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export { SidebarMenuMain }
