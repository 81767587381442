import {KTIcon} from '../../../../_metronic/helpers'

const WalletModalHeader = ({openTopUpWalletModal, setOpenTopUpWalletModal}) => {
  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>{openTopUpWalletModal.type === 'add' ? 'Add Cash to Wallet' : 'Remove Cash from Wallet'}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() =>  {
          setOpenTopUpWalletModal({
            status:false,
            type:'add'
          })
        }}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>

    </div>
  )
}

export {WalletModalHeader}
