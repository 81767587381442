import moment from "moment";
import { formatPrice } from "../../../../../_metronic/helpers/utils";

const headers = [
  { title: 'Date' },
  { title: 'Time' },
  { title: 'Transactions' },
  { title: 'Reservation Number/Remarks' },
  { title: 'Charge ID/Notes' }
]

const getTopUpType = (type, remarks) => {
  switch (type) {
    case 'topup':
      return 'User Added Funds'
    case 'admin topup':
      return 'Admin Added Funds'
    case 'reward':
      return 'Reward Deposited'
  }
}

const renderRemarks = (row) => {
  if (row.type === 'CR') {
    if (row.transaction_type === 'topup') {
      return (
        <a href={`https://dashboard.stripe.com/payments/${row.remarks}`} target='_blank'>
          {row.remarks || '-'}
        </a>
      );
    } else if (row.transaction_type === 'reward') {
      return 'Bonus Credited';
    }
  }

  if (row.type === 'DR' && row.notes) {
    return row.notes || 'Booking';
  }

  return row.remarks || 'N/A';
};



export const PointsTable = ({ points, haveAccess, currentUser }) => {
  return (
    haveAccess(currentUser, 'wallet_history') && points.length > 0 ?
      <div className='table-responsive' style={{ height: 300 }}>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map(column => (
                <th
                  key={column.title}
                  className={`${column.className} ${column.title === 'Charge ID/Notes' ? 'text-center' : ''}`}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {
              points.map((row, i) => (
                <tr key={i}>
                  <td style={{ minWidth: '180px' }}>{(moment(row.created_at).utc().format('MMM DD, YYYY'))}</td>
                  <td style={{ minWidth: '120px' }}>{(moment(row.created_at).utc().format('h:mm A'))}</td>
                  <td style={{ minWidth: '130px', color: row.type === 'CR' ? '#2ecc71' : '#ed2626' }}> {row.type === 'CR' ? `+ ${formatPrice(row.credited_balance)}` : `- ${formatPrice(row.balance_consumed)}`} {row.transaction_type === 'review' && '(Review)'}</td>
                  <td style={{ minWidth: '180px' }}>
                    {row.type === 'CR' ? getTopUpType(row.transaction_type) : row.type === 'DR' && !row.remarks ? 'Admin Deducted Funds' : row.remarks}
                  </td>
                  <td style={{ minWidth: '180px', textAlign: 'center' }}>
                    {renderRemarks(row)}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      :
      <div className='d-flex text-center w-100 align-content-center justify-content-center border border-1 border-gray-300 p-5 mb-10'>
        {haveAccess(currentUser, 'wallet_history') ?
          'No Wallet History yet for this user'
          :
          'You do not have permission to view'
        }
      </div>
  );
};
